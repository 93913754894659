@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

p,
h1,
h2,
h3,
h4,
h4,
h5 {
  margin: unset;
}
